<template>
  <div :class="{'noTop':isTop}">
    <el-row :gutter="10">
      <el-col :span="12">
        <div class="flex nav-l">
          <div>
            <el-image
              style="width: 150px; height: 100px"
              :src="logo"
            ></el-image>
          </div>
          <div>浙江共生店信息科技有限公司</div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="flex nav-r jc">
          <div v-for="(item,index) in navList" :key="index" @click="toPath(item.path)" @mouseover="showLine(index)" @mouseout="showLine(index)">
            <div class="navTitle" >
                {{ item.title }}
            </div>
            <div>
            <transition name="el-zoom-in-center">
                <div v-show="item.show" class="transition-box"></div>
            </transition>
            </div>
           </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isTop:false,
      navList: [
        { title: "首页", path: "",show:false },
        { title: "关于我们", path: "aboutAs",show:false },
        { title: "产品中心", path: "product",show:false },
        { title: "新闻中心", path: "news" ,show:false},
        { title: "联系我们", path: "contactUs" ,show:false},
      ],
      logo: require("@/assets/icon/logo.png"),
    }
  },
  methods:{
    showLine(index){
        if( this.navList[index].show){
            this.navList[index].show=false
        }else{
            this.navList[index].show=true
        }
    },
    toPath(path){
      this.$router.push('/'+path)
    },
    handleScroll() {
      this.isTop = window.scrollY !== 0;
    },
    
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  flex: 1;
  color: #fff;
  height: 100px;
  line-height: 100px;

}

.navTitle{
    width:9vw;
    text-align: center;
}
.navTitle:hover{
    cursor: pointer;
}
.nav-l{
    line-height: 100px;
    margin-left: 10%;
    font-size: 26px;
}
.nav-r{
    line-height: 100px;
    font-size: 16px;
    width:45vw;
}
.page {
  
}
.noTop{
    background: #000;
    opacity: 0.9;
}

.transition-box {
    margin-top:-3px;
    position: relative;
    width: 9vw;
    height: 3px;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
    color: #fff;
  }
</style>
