<template>
  <div>
    <div class="profileDiv">
      <div style="border: 1px solid #909399; width: 200px"></div>
      <div
        style="border: 3px solid #303133; width: 300px; margin-top: 3px"
      ></div>
      <div>
        <div class="title">企业简介</div>
        <div class="titleEn">COMPANY PROFILE</div>
        <div class="info">
          浙江共生店信息科技有限公司，坐落于风景秀丽、经济蓬勃发展的浙江省，是一家专注于健康咨询服务（非医疗）领域的创新型企业。自成立以来，我们始终秉持“以人为本，健康为先”的服务理念，致力于为广大客户提供专业、全面、贴心的健康咨询解决方案。
        </div>
        <div class="info">
          公司汇聚了一批高素质、专业化的健康顾问团队，他们拥有丰富的行业经验和深厚的专业知识，能够针对客户的个性化需求，提供精准、有效的健康咨询指导。我们的服务范围广泛，涵盖了营养膳食、运动健身、心理健康等多个领域，旨在帮助客户建立科学、健康的生活方式，提升生活品质。
        </div>
        <div class="info">
          在信息技术日新月异的今天，浙江共生店信息科技有限公司紧跟时代步伐，积极运用大数据、云计算等先进技术，不断优化服务流程，提升服务质量。我们坚信，信息科技的力量将为健康咨询领域带来革命性的变革，让更多人享受到便捷、高效的健康服务。
        </div>
        <div class="info">
          “自我机会，永远都属于信息领先，敢于探索的人！”这是我们的企业标语，也是我们不断前行的动力源泉。浙江共生店信息科技有限公司将继续秉承创新精神，勇于探索未知领域，不断拓宽服务边界，为客户提供更加优质、多元的健康咨询服务。
        </div>
        <div class="info">
          未来，我们期待与更多志同道合的伙伴携手共进，共同推动健康产业的发展，为构建健康、和谐的社会贡献我们的力量。
        </div>
      </div>
      <div
        style="
          border: 3px solid #303133;
          width: 300px;
          margin-left: 980px;
          margin-top: 30px;
        "
      ></div>
      <div
        style="
          border: 1px solid #909399;
          width: 200px;
          margin-top: 3px;
          margin-left: 1080px;
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isTop: false,
      navList: [
        { title: "首页", path: "index", show: false },
        { title: "关于我们", path: "aboutUs", show: false },
        { title: "产品中心", path: "product", show: false },
        { title: "新闻中心", path: "news", show: false },
        { title: "联系我们", path: "contactUs", show: false },
      ],
      logo: require("@/assets/icon/logo.png"),
    };
  },
  methods: {
    showLine(index) {
      if (this.navList[index].show) {
        this.navList[index].show = false;
      } else {
        this.navList[index].show = true;
      }
    },
    handleScroll() {
      this.isTop = window.scrollY !== 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.profileDiv {
  width: 1280px;
  margin: 0 auto;
  margin-top: 40px;
}
.title,
.titleEn {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.title {
  width: 300px;
  padding-top: 30px;
  line-height: 60px;
  margin: 0 auto;
  border-bottom: 4px solid #f56c6c;
}
.titleEn {
  line-height: 60px;
}
.info {
  text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
}
</style>
