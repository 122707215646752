<template>
  <div>
    <div class="profileDiv">
      <div
        style="
          border: 3px solid #303133;
          width: 300px;
          margin-left: 980px;
          margin-top: 30px;
        "
      ></div>
      <div
        style="
          border: 1px solid #909399;
          width: 200px;
          margin-top: 3px;
          margin-left: 1080px;
        "
      ></div>
      <div>
        <div class="title">新闻中心</div>
        <div class="titleEn">NEW CENTER</div>
        <div class="info">
          <el-row :gutter="10">
              <el-col :span="6" :pull="1" :offset="2" v-for="(item,index) in newsList" :key="index">
                <div class="newDiv">
                  <div style="background:#000">
                    <el-image style="width: 100%; height: 14vw" :src="logo" ></el-image>
                  </div>
                  <div class="newInfo">{{ item.title }}</div>
                </div>
              </el-col>
          </el-row>
        </div>
      </div>
      <div style="border: 1px solid #909399; width: 200px"></div>
      <div
        style="border: 3px solid #303133; width: 300px; margin-top: 3px"
      ></div>
    </div>
  </div>
</template>

<script>
import * as webApi from "../../../api/webApi";
export default {
  data() {
    return {
      isTop: false,
      newsList: ['1','3','2'
      ],
      logo: require("@/assets/icon/logo.png"),
    };
  },
  methods: {
    getNotice(){
      var that = this;
      webApi.getNotice().then((res) => {
        that.newsList = res.data.data;
      });
    }
  },
  created(){
    this.getNotice()
  }
};
</script>

<style scoped>
.profileDiv {
  width: 1280px;
  margin: 0 auto;
  margin-top: 40px;
}
.title,
.titleEn {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.title {
  width: 300px;
  padding-top: 30px;
  line-height: 60px;
  margin: 0 auto;
  border-bottom: 4px solid #f56c6c;
}
.titleEn {
  line-height: 60px;
}
.info {
 
  font-size: 16px;
}
.newDiv{
  margin-top:40px;
  margin-bottom:40px;
  border:1px solid #303030;
  width:100%;
  height:20vw;
}
.newInfo{
  font-size:16px;
  width:90%;
  margin-left:5%;
  line-height: 25px;
  font-weight: bold;
  margin-top:10px
}
</style>
