<template>
  <div class="page">
    <el-carousel :interval="5000" arrow="always" height="49vw">
    <el-carousel-item v-for="(item,ii) in bannerList" :key="ii">
      <el-image style="width: 100vw; height: 49vw" :src="item.logo"></el-image>
    </el-carousel-item>
  </el-carousel>
  </div>
</template>

<script>
import * as webApi from '../../../api/webApi'
export default {
  data() {
    return {
      bannerList:[],
    }
  },
  created(){
    this.getBannerList()
  },
  methods:{
    getBannerList(){
      var that = this
      webApi.getBannerList().then(res=>{
        that.bannerList = res.data.data
        console.log(res)
      })
    }
  }
};
</script>

<style scoped>
</style>
