<template>
  <div >
    <div class="heade">
      <div class="chTitle">共生臻选</div>
      <div class="enTitle">SYMBIOSIS HIGH QUALITY SELECTION</div>
      <!-- 轮播 -->
      <el-carousel :interval="4000" type="card" height="35vw">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="slide-image">
            <el-image
              mode="scaleToFill"
              width="100%"
              :src="item.cdrProduct.imgurl"
            >
            </el-image>
            <div class="infoText">
              <div class="pp">青春宝</div>
              <div class="productName">{{ item.cdrProduct.productname }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import * as webApi from "../../../api/webApi";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      var that = this;
      webApi.getAllCdrRecommendedProducts().then((res) => {
        that.list = res.data.data;
        console.log(res.data.data[0].cdrProduct);
      });
    },
  },
};
</script>

<style scoped lang="less">
.heade {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding-top: 40px;
}
.enTitle {
  font-size: 20rpx;
  font-weight: bold;
  margin-bottom: 40px;
}

.chTitle {
  font-size: 50rpx;
  font-weight: bold;
}
::v-deep  .el-carousel__mask {
    background-color:transparent
  }
.el-carousel__item {

  .slide-image {
    width: 50%;
    margin: 0 auto;
    color: #000;
    border-radius: 10px;
  }
}

.el-carousel__item:nth-child(2n) {
  .slide-image {
    color: #000;
    width: 50%;
    padding: 10px;
    margin: 0 auto;
    background: #fff;
  }
}

.el-carousel__item:nth-child(2n + 1) {
  .slide-image {
    padding: 10px;
    color: #000;
    width: 50%;
    margin: 0 auto;
    background: #fff;
  }
}
</style>
