<template>
  <div class="divs">
    <div class="nav divs">
        <indexNav ref="navs"/>
    </div>
    <div class="banner divs">
        <indexBanner/>
    </div>
    <div class="divs">
       <indexCompanyProfile/>
    </div>
    <div class="productList ">
        <productList/>
    </div>
    <div>
        <news/>
    </div>
    <div class="about">
        <indexAbout/>
    </div>
  </div>
</template>

<script>
import indexNav from './module/indexNav.vue'
import indexCompanyProfile from '../companyProfile/indexCompanyProfile.vue'
import indexBanner from './module/indexBanner.vue'
import productList from './module/prdocutList.vue'
import news from './module/news.vue'
import indexAbout from '../indexBtn/indexAbout.vue'
export default {
    data(){
        return{
        }
    },
    methods:{

    },
    components:{
        indexNav,
        indexBanner,
        indexCompanyProfile,
        productList,
        news,
        indexAbout
    }
}
</script>

<style  scoped>
.nav{
    width:100%;
    position:fixed;
    top:0;
    z-index: 999;
}
.divs{
    width:99vw;
    padding: 0px;
    margin:0 auto;
}
.productList{
    height:50vw;
    margin-top:40px;
    background:#000;
}
.about{
    background:#000;
    min-height:200px;
    margin-top:40px
}
</style>